<template>
    <div>
        <div class="page-title">推荐列表</div>

        <div class="parent-info" v-if="parentInfo.id">

            <div class="self-info">
                <div class=" member-card">
                    <img :src="parentInfo.avatar" alt="">
                    <span class="member-name">{{ parentInfo.mark_name || parentInfo.name }}</span>
                </div>
            </div>
            <div class="member-level" v-if="parentInfo.level_name">
                会员等级：
                <div class="level-card">{{ parentInfo.level_name }}</div>
            </div>

            <div class="child-num">
                下级数量：{{ parentInfo.child_num }}
            </div>

        </div>

        <div class="member-search">
            <a-input-search enterButton @search="search">
                <div slot="addonBefore">查询分销上下级用户</div>
            </a-input-search>
        </div>

        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无会员'}"
                 class="stock-table"
                 :loading="table.loading"
                 bordered
        >
            <div class="member" slot="member" slot-scope="val,item">
                <router-link :to="`/ast/members/${item.id}`">
                    <div class=" member-card">
                        <img :src="item.avatar" alt="">
                        <span class="member-name">{{ item.mark_name || item.name }}</span>
                    </div>
                </router-link>
            </div>

            <div class="member" slot="parent" slot-scope="val,item">
                <router-link v-if="item.parent" :to="`/ast/members/${item.parent.id}`">
                    <div class="member-card">
                        <img :src="item.parent.avatar" alt="">
                        <span class="member-name">{{ item.parent.mark_name || item.parent.name }}</span>
                    </div>
                </router-link>
                <div v-else>-</div>
            </div>

            <div slot="level_name" slot-scope="levelName,item">
                <template v-if="item.level_id">
                    <div class="level-card">
                        {{ levelName }}
                    </div>
                </template>
                <template v-else>游客</template>

            </div>

            <div slot="time" slot-scope="time">
                {{ formatTime(time) }}
            </div>

            <div slot="def" slot-scope="val">{{ val || ' - ' }}</div>


            <div slot="child" slot-scope="val,item">
                <template v-if="val > 0">
                    <router-link :to="`/ast/shop/recommend/${item.id}`">{{ val }}</router-link>
                </template>
                <template v-else> -</template>
            </div>

            <div slot="op" slot-scope="val,item">
                <button @click="activeChangeParentModal(item)" class="btn">变更上级</button>
            </div>

        </a-table>

        <at-modal v-model="showChangeParentModal" title="变更上级" @ok="confirmChangeParent">

            <div class="member-change-modal">
                <div class="tips">请选择一个用户成为「{{ selectedMember.name }}」的上级</div>
                <div class="search-input-container">
                    <at-input class="full-with search-input" v-model="memberKeywords">
                        <div @click="searchMember" class="search-btn"></div>
                    </at-input>

                    <table class="member-list-table ">

                        <tr>
                            <th>ID</th>
                            <th>用户</th>
                            <th>手机号</th>
                            <th>选择</th>
                        </tr>

                        <template v-for="(item,mk) in memberList">

                            <tr :key="mk" class="member-item">

                                <td>
                                    {{ item.member_id }}
                                </td>
                                <td>
                                    <div class=" member-card">
                                        <img :src="item.avatar" alt="">
                                        <span class="member-name">{{ item.mark_name  || item.name }}</span>
                                    </div>
                                </td>
                                <td>{{ item.phone || '-' }}</td>
                                <td>
                                    <button @click="parentSelected(item)"
                                            :class="{selected:item.id === selectedParent.id }"
                                            class="select-btn"></button>
                                </td>
                            </tr>

                        </template>
                    </table>
                </div>
            </div>

        </at-modal>

    </div>
</template>

<script>

import api from "../../../repo/api";
import moment from "moment/moment";
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";

export default {
    components: {AtInput, AtModal},
    data() {
        return {
            routeMatch: '/ast/shop/recommend',
            selectedMember: {},
            selectedParent: {},
            memberList: [],
            query: {
                status: 1
            },
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'member_id',
                        width: '110px',
                        align: 'center'
                    },
                    {
                        title: '用户',
                        dataIndex: 'member',
                        scopedSlots: {customRender: 'member'},
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone',
                        scopedSlots: {customRender: 'def'},
                        align: 'center'
                    },
                    {
                        title: '会员等级',
                        dataIndex: 'level_name',
                        scopedSlots: {customRender: 'level_name'},
                        align: 'center'
                    },
                    {
                        title: '上级',
                        dataIndex: 'parent',
                        scopedSlots: {customRender: 'parent'},
                        align: 'center'
                    },
                    {
                        title: '推荐下级数量',
                        dataIndex: 'child_num',
                        scopedSlots: {customRender: 'child'},
                        align: 'center'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'created_at',
                        scopedSlots: {customRender: 'time'},
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        scopedSlots: {customRender: 'op'},
                        align: 'center'
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
            showChangeParentModal: false,
            parentInfo: {},
            memberKeywords: '',
        }
    },
    watch: {
        '$route.params'() {
            this.getFirstList()
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        search(keywords) {
            this.query.keywords = keywords
            this.getFirstList()
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        activeChangeParentModal(member) {
            this.selectedMember = member
            this.showChangeParentModal = true
            this.searchMember()
        },
        searchMember() {
            this.memberListLoading = true

            api.get('/ast-member/members', {keywords: this.memberKeywords}, (data) => {

                this.memberListLoading = false
                let members = data.data.data;

                for (let idx in members) {

                    let member = members[idx]

                    if (member.id === this.selectedMember.parent_id) {

                        members.splice(idx, 1)

                        break;
                    }
                }

                if (members.length > 5) members = members.slice(0, 5)

                this.memberList = members
            })

        },
        getList() {

            this.table.loading = true

            this.query.parent_id = this.$route.params.id

            api.get('/ast-member/members', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

                this.parentInfo = data.data.parent || {}
            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        parentSelected(parent) {
            this.selectedParent = parent
        },
        confirmChangeParent() {
            let params = {
                am_id: this.selectedMember.id,
                parent_id: this.selectedParent.id
            }

            this.$loading.show()

            api.post('/ast-app/change-member-ref', params, (data) => {

                this.$loading.hide()
                this.$message.data(data);

                if (data.code === 0) {
                    this.showChangeParentModal = false;
                    this.getList()
                }
            })

        }
    }
}
</script>
<style lang="less" scoped>

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.member-card {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;


    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        border-radius: 50%;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.stock-table {
    margin-top: 32px;
}

.member-search {
    margin-top: 32px;
}

.self-info {
    .member-card {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;

        .member-name {
            z-index: 2;
            position: relative;
            background-color: white;
            padding-right: 10px;
        }

        img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
        }
    }
}

.parent-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    align-items: center;

    div {
        margin-right: 48px;
    }
}

.member-search {
    width: 528px;

    /deep/ .ant-input-search {
        .ant-input {
            height: 40px;
        }

        .ant-input-group-addon {
            border-radius: 0;
        }

        .ant-btn {
            height: 40px;
            background: #333333;
            border-radius: 0;
        }
    }
}

.member-change-modal {
    .tips {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.search-input {
    margin-top: 14px;

}

.member-list-table {
    width: 100%;
    margin-top: 18px;

    th, td {
        text-align: center;
        height: 40px;
        border: 1px solid #EEEEEE;
        vert-align: middle;
    }

    .member-card {
        justify-content: center;
    }
}

.search-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.select-btn {
    width: 16px;
    height: 16px;
    background-color: white;

    border: 1px solid #EEEEEE;
    cursor: pointer;

    &:hover {
        border-color: #cdcdcd;
    }

    &.selected {
        background-image: url("/assets/icons/stock-selected.png");
        background-size: 16px;
        border: none;
    }
}


</style>